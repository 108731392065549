import React from "react";
import LoginForm from "../components/LoginForm";
import { isAuthenticated } from "../utils/authHelper";
import { Navigate } from "react-router-dom";

function Login() {

    if (isAuthenticated()) {
        return <Navigate to="/home" />;
    }
    return <LoginForm />

}

export default Login;