import axios from 'axios';
import { ACCESS_TOKEN, REFRESH_TOKEN } from './constants';


const api = axios.create({
    // baseURL: import.meta.env.VITE_API_URL,
    baseURL: "https://fiox.me/",
});



api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem(REFRESH_TOKEN);
                const res = await axios.post("http://localhost:8080/api/token/refresh/", {
                    refresh: refreshToken,
                });

                if (res.status === 200) {
                    localStorage.setItem(ACCESS_TOKEN, res.data.access);
                    api.defaults.headers.common['Authorization'] = `Bearer ${res.data.access}`;
                    originalRequest.headers['Authorization'] = `Bearer ${res.data.access}`;

                    return api(originalRequest); // Yeniden orijinal isteği gönder
                }
            } catch (err) {
                // Eğer token yenileme işlemi başarısız olursa
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(REFRESH_TOKEN);
                window.location.href = "/login"; // Kullanıcıyı giriş sayfasına yönlendir
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);



export default api;