import React from "react";
import RegisterForm from "../components/RegisterForm";
import { isAuthenticated } from "../utils/authHelper";
import { Navigate } from "react-router-dom";


function Register() {
    if (isAuthenticated()) {
        return <Navigate to="/home" />;
    }
    return <RegisterForm />


}

export default Register;