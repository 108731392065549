import { useState, useEffect } from "react";
import api from "../api";
import { useNavigate, Link } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import "../styles/reqisterlogin.css";
import GoogleSignIn from "./GoogleLogin";

// import LoadingIndicator from "./LoadingIndicator";

function RegisterForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password_conf, setPassword_conf] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [valmethod, setvalmethod] = useState("email");
    const [validate_code, setvalidate_code] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [validate_input, setvalidate_input] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [countdown, setCountdown] = useState(60);

    const navigate = useNavigate();

    const handleServerError = (serverErrors) => {
        const formattedErrors = [];


        if (serverErrors.username) {
            formattedErrors.push({ field: 'username', message: serverErrors.username[0] });
        }
        if (serverErrors.password) {
            serverErrors.password.forEach(error => {
                formattedErrors.push({ field: 'password', message: error });
            });
        }
        if (serverErrors.email) {
            formattedErrors.push({ field: 'email', message: serverErrors.email[0] });
        }

        if (serverErrors.number) {
            formattedErrors.push({ field: 'number', message: serverErrors.number[0] });
        }

        if (serverErrors.code) {
            formattedErrors.push({ field: 'code', message: serverErrors.code[0] });
        }
        setErrors(formattedErrors);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

            const res = await api.post("/api/user/register/", { username, password, email, validate_code, password_conf, number, valmethod });

            if (res.status === 201) {
                setErrors([]);
                try {
                    const res = await api.post("/api/token", { username, password })
                    localStorage.setItem(ACCESS_TOKEN, res.data.access);
                    localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
                    navigate("/home")
                } catch (error) {
                    alert("Somthing went wrong!")
                } finally {
                    setLoading(false)
                }
            }

            if (res.status === 200) {
                setErrors([{ field: '', message: "code sent" }]);
                api.post("api/CreateVCode/", { email, number })
                setvalidate_input(true)
                setIsActive(false)
            }


            if (res.status === 208) {
                setErrors([]);
                setvalidate_input(true)
            }


        } catch (error) {
            handleServerError(error.response.data);
        } finally {
            setLoading(false);
        }

    };
    useEffect(() => {
        let interval = null;

        if (!isActive && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((countdown) => countdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            clearInterval(interval);
            setIsActive(true);
            setCountdown(60);
        }

        return () => clearInterval(interval);
    }, [isActive, countdown]);

    const handleClick = () => {
        api.post("api/CreateVCode/", { email, number })
        setErrors([{ message: "code sent" }]);
        setIsActive(false);
    };

    const handleval = () => {
        if (valmethod === "email") {
            setvalmethod("number");
            setEmail("");
            setNumber("");
        } else {
            setvalmethod("email")
            setEmail("");
            setNumber("");
        }
    }




    return (
        <div className="container register">

            <div className="pc">
                <div className="modal modal-sheet position-static d-block bg-body-secondary p-4 py-md-5" tabIndex="-1" role="dialog" id="modalSignin">
                    <div className="modal-dialog" role="document">

                        <div className="modal-content rounded-4 shadow">
                            <Link to="/"><i className="fas fa-beer position-absolute top-0 start-0 m-2"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg></i></Link>
                            <div className="modal-header p-5 pb-4 border-bottom-0">
                                <h1 className="fw-bold mb-0 fs-2">Sign up for free</h1>
                            </div>
                            <div className="modal-body p-5 pt-0">
                                <form onSubmit={handleSubmit}>

                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control rounded-3"
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            placeholder="Username"
                                            maxLength={30}
                                            required
                                        />
                                        <label>Username</label>
                                    </div>

                                    {valmethod === "email" ?

                                        <div className="form-floating mb-3">
                                            <input
                                                className="form-control rounded-3"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Email"
                                                maxLength={254}
                                                required
                                            />
                                            <label >Email address</label>
                                        </div>
                                        :
                                        <div className="form-floating mb-3">
                                            <input
                                                className="form-control rounded-3"
                                                type="number"
                                                value={number}
                                                onChange={(e) => setNumber(e.target.value)}
                                                placeholder="Number"
                                                required
                                            />
                                            <label >Phone Number</label>
                                        </div>

                                    }

                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control rounded-3"
                                            type="password"
                                            pattern="\S+"
                                            title="Password cannot contain spaces"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                            maxLength={128}
                                            minLength={8}
                                            required
                                        />
                                        <label >Password</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control rounded-3"
                                            type="password"
                                            pattern="\S+"
                                            title="Password cannot contain spaces"
                                            value={password_conf}
                                            onChange={(e) => setPassword_conf(e.target.value)}
                                            placeholder="Password"
                                            maxLength={128}
                                            minLength={8}
                                            required
                                        />
                                        <label>Password confirmation:</label>
                                    </div>

                                    <div style={{ display: validate_input ? 'block' : 'none' }}>

                                        <div className="input-group mb-3">

                                            <input
                                                placeholder="enter code"
                                                className="form-control rounded-3"
                                                value={validate_code}
                                                onChange={(e) => setvalidate_code(e.target.value)}
                                                pattern="\d{6}"
                                                maxLength={6} />

                                            <button
                                                id="sayacButon"
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                onClick={handleClick}
                                                disabled={!isActive}>
                                                {isActive ? 'Resend Code' : `Resend Code in ${countdown}`}
                                            </button>

                                        </div>

                                    </div>
                                    <div className="error">
                                        {errors.length > 0 && (
                                            <ul style={{ padding: "0" }}>
                                                {errors.map((error, index) => (
                                                    <li key={index}>{` ${error.message}`}</li>
                                                ))}
                                                {/* ${error.field} */}
                                            </ul>
                                        )}
                                    </div>

                                    <div style={{ marginBottom: "10px" }} >
                                        <span className="hover-underline" onClick={() => { handleval() }} >{valmethod === "email" ? "Use Phone Number" : "Use Email"}</span>
                                    </div>


                                    {loading ?
                                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary disabled" aria-disabled="true"><div className="text-center">
                                            <div className="spinner-border" role="status">
                                            </div>
                                        </div></button> :
                                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Register</button>
                                    }

                                </form>
                                <small className="text-body-secondary">By clicking Sign up, you agree to the terms of use.</small>
                                <hr className="my-4" />
                                <h2 className="fs-5 fw-bold mb-3">Sign up with Google</h2>
                                <GoogleSignIn />
                                {/* <button className="w-100 py-2 mb-2 btn btn-outline-secondary rounded-3" type="button">
                                    Sign up with Google
                                </button> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="phone">

                <Link to="/"><i className="fas fa-beer position-absolute top-0 start-0 m-2"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                </svg></i></Link>
                <div className="modal-header p-5 pb-4 border-bottom-0">
                    <h1 className="fw-bold mb-0 fs-2">Sign up for free</h1>
                </div>
                <div className="modal-body p-5 pt-0">
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                className="form-control rounded-3"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                                maxLength={30}
                                required
                            />
                            <label>Username</label>
                        </div>
                        {valmethod === "email" ?

                            <div className="form-floating mb-3">
                                <input
                                    className="form-control rounded-3"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    maxLength={254}
                                    required
                                />
                                <label >Email address</label>
                            </div>
                            :
                            <div className="form-floating mb-3">
                                <input
                                    className="form-control rounded-3"
                                    type="number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                    placeholder="Number"
                                    required
                                />
                                <label >Phone Number</label>
                            </div>

                        }
                        <div className="form-floating mb-3">
                            <input
                                className="form-control rounded-3"
                                type="password"
                                pattern="\S+"
                                title="Password cannot contain spaces"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                maxLength={128}
                                minLength={8}
                                required
                            />
                            <label >Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                className="form-control rounded-3"
                                type="password"
                                pattern="\S+"
                                title="Password cannot contain spaces"
                                value={password_conf}
                                onChange={(e) => setPassword_conf(e.target.value)}
                                placeholder="Password"
                                maxLength={128}
                                minLength={8}
                                required
                            />
                            <label>Password confirmation:</label>
                        </div>

                        <div style={{ display: validate_input ? 'block' : 'none' }}>

                            <div className="input-group mb-3">

                                <input
                                    placeholder="enter code"
                                    className="form-control rounded-3"
                                    value={validate_code}
                                    onChange={(e) => setvalidate_code(e.target.value)}
                                    pattern="\d{6}"
                                    maxLength={6} />

                                <button
                                    id="sayacButon"
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={handleClick}
                                    disabled={!isActive}>
                                    {isActive ? 'Resend Code' : `Resend Code in ${countdown}`}
                                </button>

                            </div>

                        </div>

                        <div className="error">
                            {errors.length > 0 && (
                                <ul>
                                    {errors.map((error, index) => (
                                        <li key={index}>{`${error.field} error: ${error.message}`}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: "10px" }} >
                            <span className="hover-underline" onClick={() => { handleval() }} >{valmethod === "email" ? "Use Phone Number" : "Use Email"}</span>
                        </div>

                        {loading ?
                            <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary disabled" aria-disabled="true"><div className="text-center">
                                <div className="spinner-border" role="status">
                                </div>
                            </div></button> :
                            <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Register</button>
                        }
                    </form>
                    <small className="text-body-secondary">By clicking Sign up, you agree to the terms of use.</small>
                    <hr className="my-4" />
                    <h2 className="fs-5 fw-bold mb-3">Sign up with Google</h2>
                    <GoogleSignIn />

                </div>
            </div>

        </div>
    );
}
export default RegisterForm