import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import NotFound from './pages/Notfound';
import ProtectedRoute from "./components/ProtectedRoute"
import Landing from './pages/Landing';
import Settings from "./pages/Settings";
import "./styles/app.css"
import { UserProvider } from './UserContext';
import ForgetPassword from "./pages/ForgetPassword";

function Logout() {
  localStorage.clear()
  return <Navigate to="/" />
}


function App() {

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/settings" element={<ProtectedRoute><UserProvider><Settings /></UserProvider></ProtectedRoute>} />
        <Route path="/home" element={<ProtectedRoute><UserProvider><Home /></UserProvider></ProtectedRoute>} />
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/reset_password" element={<ForgetPassword />} />

      </Routes>
    </BrowserRouter>

  )
}

export default App