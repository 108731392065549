import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import api from "../api";

const clientId = '527057410181-4h0g2dou9088be5huj49fpn5ppvfqfaj.apps.googleusercontent.com';

const handleSucces = async (token, navigate) => {
  try {
    const res = await api.post("/api/auth/google/", { token })
    console.log(res.data);
    localStorage.setItem(ACCESS_TOKEN, res.data.access);
    localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
    navigate("/home")
  }
  catch (error) {
    console.log(error.response.data);
  }
};
const onFailure = () => {
  console.log('Login failed');
  alert('Failed to login');
};

function GoogleSignIn() {

  const navigate = useNavigate();
  const onSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    handleSucces(token, navigate);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onFailure}
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleSignIn;