import React from "react";
import { isAuthenticated } from "../utils/authHelper";
import { Navigate, Link, useNavigate } from "react-router-dom";
import '../styles/landing.css';

function Landing() {
    const navigate = useNavigate();
    if (isAuthenticated()) { return <Navigate to="/home" />; }

    return (
            <div className="container landing">
                
                <div className="pc">
                    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">

                        <div className="col-md-3 mb-2 mb-md-0 icon">
                            <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                </svg>
                            </a>
                        </div>
                        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 helpers">
                            <li><a href="/" className="nav-link px-2">Home</a></li>
                            <li><a href="/" className="nav-link px-2">Features</a></li>
                            <li><a href="/" className="nav-link px-2">Pricing</a></li>
                            <li><a href="/" className="nav-link px-2">FAQs</a></li>
                            <li><a href="/" className="nav-link px-2">About</a></li>
                        </ul>

                        <div className="col-md-3 text-end forms">
                            <Link to="/login"><button type="button" className="btn btn-outline-primary me-2">Login</button></Link>
                            <Link to="/register"><button type="button" className="btn btn-primary">Sign-up</button></Link>
                        </div>
                    </header>
                </div>
                
                <div className="phone">
                    <nav className="navbar navbar-expand-md navbar" aria-label="Fourth navbar example">
                        <div className="container-fluid">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                            </svg>


                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                            </svg>

                            <div className="collapse navbar-collapse " id="navbarsExample04">
                                <ul className="navbar-nav me-auto mb-2 mb-md-0 align-items-center justify-content-center">
                                    <li><a href="/" className="nav-link px-2">Home</a></li>
                                    <li><a href="/" className="nav-link px-2">Features</a></li>
                                    <li><a href="/" className="nav-link px-2">Pricing</a></li>
                                    <li><a href="/" className="nav-link px-2">FAQs</a></li>
                                    <li><a href="/" className="nav-link px-2">About</a></li>
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                        <button onClick={() => navigate('/login')} type="button" className="btn btn-outline-primary">Login</button>
                                        <button onClick={() => navigate('/register')} type="button" className="btn btn-primary">Sign-up</button>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>
    )
} export default Landing;