// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import api from "./api";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {


        api.get("api/UserData/").then((response) => {
            if(response.data.number == null){
                response.data.number = "";
            }
            setUser(response.data);
            console.log(response.data);

            setLoading(false);
        }
        ).catch((error) => {
            setLoading(false);
            console.error(error.response.data);
            if (error.response.status === 401) {
                window.location.reload();
                // console.error(error.response.data);
            }
        });

    }, []);

    if (loading) {
        return <div id='loading-spinner'><div className="spinner-border text-primary" role="status">
        </div></div>;
    }
    return (
        <UserContext.Provider value={{ user, setUser}}>
            <Navbar />
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
