import React, { useState } from 'react';
import api from "../api";
import '../styles/home.css';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

function Home() {

    const { user, setUser } = useContext(UserContext);

    function setReplyList(data) {
        setUser({ ...user, replys: data });
    }

    let replyList = user.replys;

    function Replys({ data }) {
        const [account, setAccount] = useState("");
        const [link, setLink] = useState("");
        const [errors, setErrors] = useState([]);
        const [show, setShow] = useState(false);

        const handleShow = () => {
            setLink("");
            setAccount("");
            setErrors([]);
            setShow(true);
        };
        const handleClose = () => setShow(false);

        const handleSubmit = async (e) => {
            e.preventDefault();

            api.post("/api/CreateReply/", {
                account: account,
                link: link,
            }).then((response) => {
                setReplyList([...data, response.data]);
                handleClose();
            }).catch((error) => {
                setErrors([error.response.data]);
            });
        }

        function del(pk) {
            api.delete(`/api/DeleteReply/${pk}/`).then((response) => {
                setReplyList(data.filter((item) => item.id !== pk));
            }).catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                }
            });
        }

        return (
            <main className="container" style={{ paddingBottom: "200px" }}>

                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    {/* <h6 className="border-bottom pb-2 mb-0">replys</h6> */}

                    {/* replys: */}
                    {data.map(item => (

                        <div key={item.id} className="d-flex text-body-secondary pt-3">

                            <button type="button" onClick={() => { del(item.id) }} className="btn btn-secondary" style={{ marginRight: "7px" }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                                </svg>
                            </button>
                            <p style={{ wordWrap: 'break-word', maxWidth: window.innerWidth - 100 }} className="pb-3 mb-0 small lh-sm border-bottom">
                                <strong className="d-block text-gray-dark">{item.account}</strong>
                                <a href={item.link} rel="noreferrer" target="_blank">{item.link}</a>
                            </p>
                        </div>

                    ))}


                    {/* add button */}
                    <div className="d-flex text-body-secondary pt-3">

                        <button type="button" onClick={handleShow} className="btn btn-secondary" style={{ marginRight: "7px" }} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                            </svg>
                        </button>

                    </div>


                    {/* modal */}
                    {show && (
                        <div className="modal fade show overlay" tabIndex="-1" role="dialog" >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Add Reply</h1>
                                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control rounded-3"
                                                    id="floatingAccount"
                                                    maxLength={50}
                                                    value={account}
                                                    onChange={(e) => setAccount(e.target.value)}
                                                    placeholder="account"
                                                    required
                                                />
                                                <label htmlFor="floatingAccount">Account</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="link"
                                                    className="form-control rounded-3"
                                                    id="floatingLink"
                                                    maxLength={200}
                                                    value={link}
                                                    onChange={(e) => setLink(e.target.value)}
                                                    placeholder="link"
                                                    required
                                                />
                                                <label htmlFor="floatingLink">Link</label>
                                            </div>
                                            <div className="error">
                                                {errors.length > 0 && (
                                                    <ul>
                                                        {errors.map((error, index) => (
                                                            <li key={index}>{`${Object.keys(error)[0]} : ${Object.values(error)[0]}`}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Add Reply</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </main>
        )
    }

    return (<div>

        <Replys data={replyList} ></Replys>

    </div>
    )
} export default Home;